.dialog {
    background-image: url('../images/WhiteMarble2.png');
    background-size: cover;
    border: 4px solid #9c8c71;
    padding: 10px 10px 10px 10px;
    min-width:50%;
    z-index: 1;
    border-radius: 10px;
}

.landing__img{
    /* padding and border radius for front page */
    border: 3px solid #9c8c71;
    padding: 3px;
    border-radius: 10px;
}

.landing__img:hover {
    opacity: 0.90;
}

.center {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
}

.dialog-backdrop {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    
}

.responsive {
    width: 100%;
    height: auto;
}

.pic-div:hover {
    opacity: 0.90;
}

.dialog-message {
    text-align: end;
    margin: -15px -8px -5px -5px;
    
}

.dialog-icon {
    min-width: 40px;
    min-height: 40px;
}

.client-image-component {
    border-radius: 10px;
    
}


/* @media screen and (max-width: 540px) { 
    .dialog-message {
        text-align: center;
        margin: -15px -8px -5px -5px;
    }
} */


