.faqs-title {
    /* font-family: SLRScript; */
    min-width: 100vw;
    font-weight: 300;
    color: #B8C2C1;
    padding-top: 8px;
    /* background-color: #BCCBC9; */
    background-image: linear-gradient(90deg, #625A52 50%, #2B2724);
    font-family: Montserrat;
}

.faqs-body {
    text-align: center;
    background-color: #BCCBC9;
    background-image: linear-gradient(90deg, #625A52 50%, #2B2724);
    padding-top: 86px;
    font-family: Montserrat;
    
}

.accordion-button {
    background-color: #BCCBC9;
    background-image: linear-gradient(90deg, #625A52 50%, #2B2724);
    color: #B8C2C1;
    border: solid #545B5A;
    border-width: 2px 3px 1px 3px;
    margin: -1px 0px 0px 0px;
    
}

.accordion-button:not(.collapsed) {
    color: #B8C2C1;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #545B5A;
    background-color: #BCCBC9
    
}

.accordion-button::after {
    color:#545B5A;
    
}
.accordion-button:not(.collapsed) {
    background-color: #BCCBC9;
}
.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-header:hover {
    opacity: 0.9;
}

.accordion-body {
    white-space: pre-wrap;
    color:#2B2724;
    background-color: #B8C2C1;
}