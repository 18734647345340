.Bridal {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../images/WhiteMarble2.png');
    background-size:cover;
}

.Carousel {
    min-width:60vw;
    max-width:60vw;
    padding-top: 86px;
    z-index: 1;
}

/* .carousel-control {
    color: #838587;
} */

.carousel-control-prev-icon, .carousel-control-next-icon{
    background-color: #838587;
    border-radius: 50%;
}

.carousel-indicators {
    background-color: #d3d1cd53;
}

.carousel-indicators [data-bs-target] {
    background-color:rgba(0, 0, 0, 0.7);
    height: .5rem;
}

.carousel-indicators [data-bs-target]:hover {
    background-color: black;
}

.carousel-control-prev-icon {
    background-image: url('../images/left-chevron.png');
}

.carousel-control-next-icon {
    background-image: url('../images/right-chevron.png');
}

.carousel-img {
    max-width: 80%;
    max-height: 60%;
}


@media screen and (max-width: 768px) { 
    .Carousel {
        min-width:105vw;
        max-width:105vw;
        min-height: 73vh;
    }
}