.sliders-body {
    text-align: center;
    background-color: #BCCBC9;
    padding: 1rem;
    padding-top: 86px;
}

.sliders-inner {
    display:grid;
    grid-template-columns: auto auto;
    background-color:#BCCBC9;
}
.__rcs-handle-button {
    color: #BCCBC9
}

.__rcs-handle-line{
    color: #545B5A;
}

.sliders-title {
    font-family: SLRScript;
    font-weight: 300;
    color: #545B5A;
    padding-top: 8px;
    margin-bottom: 0px;

}

.sliders-header {
    font-family: SLRScript;
    font-weight: 300;
}