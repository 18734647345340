.navbar {
    display: flex;
    color: #545B5A;
    justify-content: center;
    flex-direction: column;
    background-image: url('../images/WhiteMarble2.png');
    background-size: cover;
    min-width: 100%;
    /* min-height: 5rem; */
    border-top: 4px solid #9c8c71;
    padding: 0px;
}

@font-face {
    font-family: SLRScript;
    src: url('../componentcss/Playfair_Display/static/PlayfairDisplay-Italic.ttf');
}

.footerlink {
    text-decoration: none;
    color: #545B5A;
    font-weight:500;
    font-size: 16px;
    font-family: Montserrat;
    

}

.footerlink:hover {
    color: #9c8c71;
}

.footerlink:active {
    color: #838587;
}

.footerlinks {
    display: flex;
    justify-content:space-evenly;
}

.footer__text {
    font-family: Montserrat;
    font-weight: 100;
    font-size: 1rem;
}

.footer-contact {
    font-weight: 900;
    font-size: 0.9rem;
    font-family: Montserrat;
}

@media screen and (max-width: 768px) { 
    .footerlink {
        font-size: 0.55rem;
    }
}

.footer-info {
    text-decoration: none;
    color:#545B5A;
    font-size: 0.9rem;
    height:50px;
    width: 50px;
}

.tiktokicon {
    text-decoration: none;
    color:#545B5A;
    height:25px;
    width: 25px;
    fill:#545B5A;
}


.footer-info:hover {
    color:#9c8c71;
}

.footer-info:active {
    color: #838587;
}

.tiktokicon:hover {
    fill:#9c8c71;
    color:#9c8c71;
}

.tiktokicon:active {
    color: #838587;
    fill:#838587;
}


