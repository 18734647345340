@font-face {
    font-family: SLRScript;
    src: url('../componentcss/Playfair_Display/static/PlayfairDisplay-Italic.ttf');
}

.clients-body {
    background-image: url('../images/WhiteMarble2.png');
    text-align: center;
    padding-top: 86px;
}

.client-pic {
    width: 100%;
    margin: 7.5px 7.5px 7.5px 7.5px;
    /* border:#838587 solid 3px; */
    /* background-color: #838587; */
    border-radius: 10px;
}

.clients-title {
    font-family: SLRScript;
    min-width: 100vw;
    font-weight: 300;
    color: #545B5A;
    padding-top: 8px;
}

@media screen and (max-width: 767px) { 
    .client-pic {
        width: 100%;
    }

    .pic-div {
        max-width: 44%;
    }
}

.clients-pics {
    display:flex;
    flex-wrap: wrap;
}

.pic-div {
    width:48%;
    margin: 0px 12px 0px 4px;
    justify-content: space-around;
}
