@font-face {
    font-family: SLRScript;
    src: url('../componentcss/Playfair_Display/static/PlayfairDisplay-Italic.ttf');
}

.LaunchBtn {
    color: #545B5A;
    outline: none;
    border-color: #fff;
    min-width: 40px;
    min-height: 40px;
    margin: 23px 25px 23px 25px;
    align-content: space-around;
}

.MenuPlaceholder {
    min-width: 90px;
    min-height: 82px;
}

.LaunchBtn:hover {
    color: #9c8c71;
    outline: none;
    /* border-color: #838587;
    border-width: 5px; */
}

.LaunchBtn:active {
    color: #838587;
}

.LaunchBtn:after {
    color: black;
}

.OffCanvasChannels {
    display: flex;
    align-content: space-around;
}

.Channel {
    text-decoration: none;
    color: black;
    font-size: 1rem;
}

.Channel:hover {
    text-decoration: none;
    color: black;
    opacity: 0.7;
}

.Channelname {
    padding: 3px 0px 3px 0px;
    
}

.Channelname:hover {
    color:black;
    background-color:#9c8c71;
    border-radius: 20px;
    margin-left: 7rem;
    margin-right: 7rem;
}

.closeBtn:focus {
    color:#545B5A;
}

.btn-close {
    opacity: .8;
    color: black;
}
.btn-close:hover {
    background-color:#9c8c71
}

.btn-close:active {
    background-color: #545B5A;
    outline: 3px solid black !important;
}

.offcanvas-header {
    background-image: url('../images/WhiteMarble2.png');
    background-size: cover;
    border-bottom: 4px solid #9c8c71;
    max-height: 86px;
    min-height: 86px;
}

.OffCanvasMain {
    opacity: 0.75;
}

.modal-open {
    padding-right: 0px !important;
}

.offcanvas-backdrop {
    background-color: black;
}

@media screen and (max-width: 996px) { 
    .Channelname:hover {
        margin-left: 7rem;
        margin-right: 7rem;
    }
    
}

@media screen and (max-width: 768px) { 
    .Channelname:hover {
        margin-left: 4rem;
        margin-right: 4rem;
    }
    
}