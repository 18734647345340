@font-face {
    font-family: SLRScript;
    src: url('../componentcss/Playfair_Display/static/PlayfairDisplay-Italic.ttf');
}

.responsive {
    width: 100%;
    height: auto;
}

.client-pic{
    /* padding and border radius for client page */
    border: 3px solid #9c8c71;
    padding: 3px;
    border-radius: 10px;
}

.dialog-content {
    display: flex;
    flex-direction: column;
    background-image: url('../images/WhiteMarble2.png');
    background-size: cover;
    border: 3px solid #9c8c71;
    z-index: 1;
    padding: 0px 5px 5px 5px !important ;
    margin: 0px 0px 0px 0px !important ;
}

div::-webkit-scrollbar {
    width: 12px;
}

div::-webkit-scrollbar-track {
    background-image: url('../images/WhiteMarble2.png');
    background-size:cover;
    
}

div::-webkit-scrollbar-thumb {
    background-color: #BCCBC9;
    border-radius: 20px;
    border: 3px solid #838587;
}

.dialog-button {
    color: #838587;
}

.center {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
}

.dialog-backdrop {
    background-color: rgba(0, 0, 0, 0.9);
}

.dialog-message {
    font-family: SLRScript;
    text-align: end;
    margin: -15px -8px -5px -5px;
}

.dialog-icon {
    min-width: 30px;
    min-height: 30px;
    color: #9c8c71;
}

.dialog-icon-button.dialog-icon-button2 {
    padding: 0px;
}

@media screen and (max-width: 540px) { 
    .dialog-message {
        text-align: center;
        margin: -15px -8px -5px -5px;
    }
}

.client-image-client {
    border-radius: 10px;
}

.pic-div_no-opacity {
    width: 48%;
    margin: 0px 12px 0px 4px;
    justify-content: space-around;
}

