@font-face {
    font-family: SLRScript;
    src: url('../componentcss/Playfair_Display/static/PlayfairDisplay-Italic.ttf');
}

.videos-body {
    background-image: url('../images/WhiteMarble2.png');
    text-align: center;
    padding-top: 86px;
}

.videos-pic {
    width: 100%;
    margin: 7.5px 7.5px 7.5px 7.5px;
    border:#545B5A solid 3px;
    background-color: #545B5A;
    border-radius: 20px;
}

.videos-title {
    font-family: SLRScript;
    min-width: 100vw;
    font-weight: 300;
    color: #545B5A;
    padding-top: 8px;
}

@media screen and (max-width: 767px) { 
    .videos-vids {
        width: 100%;
    }

    .videoTag2 {
        max-width: 50vw;
    }
}

.videos-vids {
    display:block;
}

.videoTag2 {
    min-width:10rem;
    max-height: 80vh;
    padding: 20px 20px 20px 20px;
    border-radius: 25px;
}

