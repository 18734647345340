.header {
    display: flex;
    position:fixed;
    top: 0;
    transition:top 0.75s;
    min-width: 100vw;
    text-align: center;
    height: 86px;
    justify-content:space-between;
    background-image: url('../images/WhiteMarble2.png');
    background-size: cover;
    border-bottom: 4px solid #9c8c71;
    z-index: 2;
}

.logo {
    margin: 5px 0px 0px 0px;
    padding-left: 20px;
    height: auto;
    max-height: 76px;
    filter: drop-shadow(0px 1000px 0 #545B5A);
    transform: translateY(-1000px);
}

.button-div {
    max-height: 50px;
    height: auto;
    margin-right: 20px;
}

.order-button {
    display: flex;
    color: #545B5A;
    background: none;
    font-weight: 900;
    /* border: 2px solid #545B5A; */
    font-family: Montserrat;
    border: none;
    padding: 3px 2px 2px 2px;
    width: 40px;
}

.order-button:hover {
    color: #9c8c71;
}

.order-button:active {
    color: #838587;
    font-weight: 900;
    /* border: 3px solid #545B5A; */
    border-radius: 10px;
}


.order-link {
    text-decoration: none;
    display: flex;
    justify-content:center;
    width: 90px;
}

.order-link-arrow {
    display: flex;
    align-self: center;
    flex-direction: row;
    color:#545B5A;
    text-decoration: none;
    border: 2px solid #545B5A;
    border-radius: 7px;
}


@media screen and (max-width: 768px) { 
    .button-div {
        max-height: 50px;
        height: auto;
        width: 82px;
        margin: 5px 15px 05px 0px;
    }
}