.about {
    background-color: #BCCBC9;
    color: #BCCBC9;
    text-align: center;
    font-size: .9rem;
    font-weight: 900;
    flex-grow: 1;
    height: 100%;
    font-family: Montserrat
}

.responsive {
    width: auto;
    height: auto;

}


.about__main {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    padding-top: 86px;
    background-color: #2B2724;
    /* background-image: linear-gradient(90deg, #2B2724 30%,  #79706A); */
}

.about__left {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about__left img {
    border :5px solid #79706A;
    justify-content: center;
}



.about__right {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 50px;
    align-items: center;
    /* background-color: #545B5A; */
    background-image: linear-gradient(90deg, #766E65 10%, #2B2822);
    /* background-image:url(../images/Consultation\ Page.jpg); */
    /* border :0px 0px 0px 3px solid #9c8c71; */
}

@media screen and (max-width: 1024px) { 
    .about__main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .about__left {
        min-height: 100%;
        padding: 20px;
        justify-content: center;
    }

    .about__left > img {
        max-height: 100%;
        background-position: center;
        background-size:contain;
        /* border: 5px solid #838587; */
        padding: 20px;
        
    }
}

@media screen and (min-width: 768px) {
    .about__left {
        min-width: 50%;
        padding: 20px;
        justify-content: center;
    }

    .about__left > img {
        padding: 20px;
        min-height: 100%
    }
}

