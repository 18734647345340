@font-face {
    font-family: SLRScript;
    src: url('../componentcss/Playfair_Display/static/PlayfairDisplay-Italic.ttf');
}

.landingpane {
    display: flex;
    flex-direction: column;
    background-image: url('../images/WhiteMarble2.png');
    background-size:contain;
    height: fit-content;
    font-family: Montserrat;
    font-size: 1.7rem;
    min-width: 100vw;
    font-weight:900;
    /* color:#625A52; */
    color:#545B5A;
    padding-top: 86px;

    
    /* text-transform: uppercase;  */
    text-shadow: 1px 1px 1px #ffffff,
        1px 2px 1px #919191,
        1px 3px 1px #919191,
        1px 4px 1px #919191,
        1px 5px 1px #919191,
        /* 1px 6px 1px #919191,
        1px 7px 1px #919191,
        1px 8px 1px #919191,
        1px 9px 1px #919191,
        1px 10px 1px #919191, */
    1px 18px 6px rgba(16,16,16,0.4),
    1px 22px 10px rgba(16,16,16,0.2),
    1px 25px 35px rgba(16,16,16,0.2),
    1px 30px 60px rgba(16,16,16,0.4);



}

.img-fluid {
    min-width: 100%;
}

.videoTag {
    margin: 0px 0px 5px 0px;
    padding: 0px 0px 4px 0px;
    filter: drop-shadow(0 0 .5rem #838587);
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
    /* border-radius: 0px 0px 10px 10px; */
}


.dressdisplay {
    display: flex;
    justify-content: space-around;
    margin: 0px 0px 0px 0px;
    
}

.top-display {
    padding: 0px 20px 0px 5px;
}


.dressdisplay-last {
    display: flex;
    padding: 0px 0px 15px 0px;
}

.landing__img {
    display: flex;
    justify-content: space-around;
    margin: 0px 2.5px 0px 2.5px;
    height: 100%; 
    overflow: hidden; 
    border-radius: 20px;
    
}

.landing__img img {
    height: 100%; 
    width: 100%;
    /* padding: 4px; */
    border-radius: 5px;
    
}


.vidpic-div {
    display: flex;
    margin: 7.5px 0px 7.5px 0px;
}

.dressdisplay-bottom {
    margin: 0px 20px 0px 6px;
}

.wing {
    height: 2rem;
}

.wing-reverse {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    margin: 0px !important;
    align-self: top;
    max-height: calc(100vh - 16px)!important;
    max-width: calc(100vw - 16px)!important ;
}

@media screen and (max-width: 970px) { 
    .top-display {
        padding: 0px 20px 0px 5px;
    }
    
}


@media screen and (max-width: 768px) { 
    .landingpane {
        font-size: 1rem;
    }

    .dressdisplay-bottom {
        margin: 0px 8px 0px 6px;
    }

    .top-display {
        padding: 0px 8px 0px 5px;
    }
    
}



