@font-face {
    font-family: SLRScript;
    src: url('../componentcss/Playfair_Display/static/PlayfairDisplay-Italic.ttf');
}

.field-label {
    font-size: 0.9rem;
    font-weight: 400;
    display: flex;
    justify-content:center;
}


.contact-title {
    /* color:black; */
    color: #BCCBC9;
}

.contact-text {
    font-family: Montserrat;
    color: #BCCBC9;
}

.arrow {
    filter: drop-shadow(0px 1000px 0 #BCCBC9);
    transform: translateY(-1000px);
}

.contact-body {
    /* background-color: #BCCBC9; */
    background-image: linear-gradient(90deg, #625A52 50%, #2B2724);
    min-height: 100vh;
    padding-top: 86px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    /* padding: 20px 20px 20px 20px; */
    /* border-radius: 10px; */
    /* border: 5px solid #838587; */
    margin: 0 auto;
    /* background-image: url('../images/WhiteMarble2.png'); */
    background-position: center;
    background-size: cover;
    /* background-color: #BCCBC9; */
    background-image: linear-gradient(90deg, #625A52 50%, #2B2724);
}

.form-check {
    font-size: 1rem;
    font-weight: 700;
    /* color: black; */
    color: #BCCBC9 !important ;
    padding: 1.5px 7.5px 1.5px 7.5px;
    /* text-align: center; */
    flex-direction: row;
}

.form-check-input {
    font-size: 1rem;
    font-weight: 700;
    background-color: #E9ECE6;
}

.form-check-input:checked {
    background-color: #545B5A;
    border-color: #545B5A;
}

input[type="checkbox"]:checked {
    box-shadow: 0 0 0 3px #545b5a64;
}

input[type="checkbox"]:active {
    box-shadow: 0 0 0 3px #545b5a7b;
    border-color: #545b5a7b;
}

input[type="radio"]:checked {
    box-shadow: 0 0 0 3px #545b5a64;
}

input[type="radio"]:active {
    box-shadow: 0 0 0 3px #545b5a7b;
    border-color: #545b5a7b;
}

.contact-image {
    display: flex;
    flex-direction: column;
    width: 85%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.contact-image img {
    margin-bottom: 5px;
}

.contact-names {
    justify-content:center;
    display: flex;
}

.contact-form-inputs{
    border-top: 3px dashed #9c8c71;
}
.contact-details {
    display: flex;
    justify-content:center;
}

.contact-dropdown {
    display: flex;
    justify-content:space-evenly;
}

label {
    /* color: black; */
    font-size: 1rem;
    font-weight: 700;
    color: #BCCBC9 !important;
}

fieldset {
    margin: 0rem 2rem 1.5rem 2rem;
    color: #BCCBC9 !important;
}

.label-input {
    font-size: 0.9rem;
    font-weight: 500;
    
}

@media screen and (max-width: 992px) { 
    .contact-names {
        display: flex;
        flex-direction: column;
    }

    fieldset {
        margin: 0rem 0rem 1.5rem 0rem;
    }

    .contact-details {
        display: flex;
        flex-direction: column;
    }

    .contact-dropdown {
        display: flex;
        flex-direction: column;
        justify-content:space-evenly;
    }

    .contact-form {
        min-width: 100%;
    }
    
    .selector{
        margin: 5px 0px 5px 0px;
    }
    
    .contact-body {
        min-height: 100vh;
    }
}

#fnameinput,#lnameinput,#phoneinput,#emailinput {
    min-width:15rem;
    background-color: #fff;
    border-radius: 5px;
    /* border:1px solid #545B5A; */
}

#fnameinput:hover, #lnameinput:hover,#phoneinput:hover,#emailinput:hover {
    border-radius: 5px;
}

#size-selector {
    background-color:#fff;
}

.MuiOutlinedInput-root {
    background-color:#fff;
}

.contact-button {
    background-color: #BCCBC9;
    color: #9c8c71;
    font-weight: 900;
    border: 3px solid #9c8c71;
    border-radius: 5px;
}

.contact-button:active {
    background-color: #545B5A;
    color: #BCCBC9;
    font-weight: 900;
    border: 3px solid #545B5A;
}

.modal {
    color:#545B5A;
}

.contact-img {
    min-width: 100%;
}

.contact-img-text {
    color: black;
    font-size: 2.55rem;
    font-weight: 500;
    position:absolute;
    top: 16.5%;
    left: 10%;
    transform: translate(-50%, -50%);
    font-family: Montserat;
}

@media screen and (max-width: 768px) { 
    .inspiration {
        margin: 0rem 0rem 0rem 7rem;
    }
}

.videoTagContact {
    margin: 0px 0px 5px 0px;
    background-color: black;
    filter: drop-shadow(0 0 .5rem #838587);
    max-width: 80%;
    min-width: 80%;
    margin: 0 auto;
    border: solid #9c8c71;
    border-width: 0px 3px 3px 3px;
}

.comment_box {
    display: block;
    margin: 0 auto;
}

.comments {
    display: block;
    margin: 0 auto;
    border: none;
}

.comments:focus, textarea:focus {
    box-shadow: 0 0 5px #BCCBC9;
    outline: solid 3px #9c8c71;
    color: #545B5A;
}

.inspiration {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-email {
    text-decoration: none;
    color:#545B5A;
}
.contact-email:hover {
    color:black;
}

.date-picker {
    margin: 0 auto;
    background-color: #fff;
    padding: 0px;
    border-radius: 5px;
}

.react-date-picker__wrapper {
    border-radius: 5px;
}




