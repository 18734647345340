@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url('../componentcss/montserrat-font/MontserratRegular-BWBEl.ttf') 
}

.consultations-title {
    font-family: Montserrat;
    font-weight: 300;
    /* color: #545B5A; */
    color:#BCCBC9;
    padding-top: 8px;
    /* background-color: #BCCBC9; */
    /* background-color: #766E65 ; */
    background-image: linear-gradient(90deg, #766E65 10%, #2B2822);
    text-align:center;
}
/* #766E65  #2B2822 */
.consultations-body {
    text-align:left;
    /* background-color: #BCCBC9; */
    /* background-color: #766E65; */
    background-image: linear-gradient(90deg, #766E65 10%, #2B2822);
    padding: 1rem 3rem 1rem 3rem;
    padding-top: 86px;
    color:#BCCBC9;
    font-weight: 800;
}

.consultations-header {
    font-family: Montserrat;
    font-weight: 800;
    text-align:center;
    color:#BCCBC9;
}

.consultations-link {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.669);
}

/* .process {
    padding-top: 86px;
    max-width: 100vw;
    
}

.consultations {
    background-color: #79706A;
} */
