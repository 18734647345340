.terms-title {
    font-family: Montserrat;
    font-weight: 300;
    color: #B8C2C1;
    padding-top: 8px;
    /* background-color: #BCCBC9; */
    background-image: linear-gradient(90deg, #625A52 50%, #2B2724);
    
}

.terms-body {
    font-family: Montserrat;
    text-align: center;
    /* background-color: #BCCBC9; */
    background-image: linear-gradient(90deg, #625A52 50%, #2B2724);
    padding: 86px 25px 0px 25px;
    color: #B8C2C1;
}

.terms-header {
    font-family: Montserrat;
    font-weight: 300;
}
